const TICKET_TYPES = [
	{ code: 1, desc: `Problem / Troubleshooting` },
	{ code: 2, desc: `Request` },
	{ code: 3, desc: `Internal` },
	{ code: 4, desc: `Other` },
	{ code: 5, desc: `Corporate IT` },
];
const TICKET_SUBTYPES = [
	// Problem
	{ code: 1, typeCode: 1, desc: "Service Issue" },
	{ code: 2, typeCode: 1, desc: "Latency" },
	{ code: 3, typeCode: 1, desc: "Bandwidth Utilization Notification" },
	{ code: 4, typeCode: 1, desc: "Broken / Defective / Missing Equipment" },
	{ code: 5, typeCode: 1, desc: "Backhaul Issue" },
	{ code: 6, typeCode: 1, desc: "Outage" },
	{ code: 12, typeCode: 1, desc: "Customer Requested Problem" },
	{ code: 32, typeCode: 1, desc: "Proactive Notification" },
	// Request
	{ code: 7, typeCode: 2, desc: "OTA" },
	{ code: 8, typeCode: 2, desc: "Comm Check" },
	{ code: 9, typeCode: 2, desc: "Installation" },
	{ code: 10, typeCode: 2, desc: "Site Qualification" },
	{ code: 11, typeCode: 2, desc: "Site Survey" },
	{ code: 30, typeCode: 2, desc: "Tunnel Bounce" },
	{ code: 31, typeCode: 2, desc: "WAN IP Change" },
	// Internal
	{ code: 13, typeCode: 3, desc: "Internal" },
	// Other
	{ code: 14, typeCode: 4, desc: "Other" },
	// Corporate IT
	{ code: 15, typeCode: 5, desc: "E-mail" },
	{ code: 16, typeCode: 5, desc: "Account Fix" },
	{ code: 17, typeCode: 5, desc: "Computer Support" },
	{ code: 18, typeCode: 5, desc: "Server Support" },
	{ code: 19, typeCode: 5, desc: "Production Support" },
	{ code: 20, typeCode: 5, desc: "Application Support" },
	{ code: 21, typeCode: 5, desc: "Phone" },
	{ code: 22, typeCode: 5, desc: "Networking" },
	{ code: 23, typeCode: 5, desc: "Employee Start/Stop" },
	{ code: 24, typeCode: 5, desc: "Electrical Engineering Support" },
	{ code: 25, typeCode: 5, desc: "Network Engineering Support" },
	{ code: 26, typeCode: 5, desc: "Laptop Support" },
	{ code: 27, typeCode: 5, desc: "Facilities" },
	{ code: 28, typeCode: 5, desc: "Purchasing" },
	{ code: 29, typeCode: 5, desc: "Printer Support" },
];
const TICKET_STATUSES = [
	{ code: 1, desc: `Open` },
	{ code: 2, desc: `Closed` },
	{ code: 3, desc: `Pending Customer` },
	{ code: 4, desc: `Pending Ventus` },
	{ code: 5, desc: `Pending Carrier` },
	{ code: 6, desc: `Pending Shipment` },
];
const TICKET_DISABLED_SECONDARY_STATUSES = [
	1, 24, 25, 26, 27, 28, 29, 30, 31, 23, 32,
];
const TICKET_SECONDARY_STATUSES = [
	//{ code: 1, statusCode: 1, desc: `Auto-Opened` },
	{ code: 2, statusCode: 1, desc: `Active` },
	{ code: 3, statusCode: 1, desc: `Working` },
	{ code: 35, statusCode: 1, desc: `Monitoring` },
	{ code: 4, statusCode: 4, desc: `Issue Escalated - Pending Review` },
	{ code: 5, statusCode: 4, desc: `Pending Ventus Tech Visit` },
	{ code: 6, statusCode: 4, desc: `Carrier Network Outage` },
	{
		code: 7,
		statusCode: 4,
		desc: `Internal Problem - Network (Unscheduled)`,
	},
	{ code: 8, statusCode: 4, desc: `Internal Problem - Network (Scheduled)` },
	{ code: 9, statusCode: 4, desc: `Internal Problem - Hardware` },
	{ code: 10, statusCode: 4, desc: `Internal Problem - Other` },
	{
		code: 11,
		statusCode: 4,
		desc: `Pending Provisioning/Shipment Replacement`,
	},
	{ code: 12, statusCode: 3, desc: `Customer Backhaul Outage` },
	{
		code: 13,
		statusCode: 3,
		desc: `Pending Installation of Replacement Equipment`,
	},
	{ code: 14, statusCode: 3, desc: `Pending Customer - Tech Visit` },
	{ code: 15, statusCode: 3, desc: `Pending Customer - Network Issue` },
	{ code: 16, statusCode: 3, desc: `Customer No Response` },
	{ code: 17, statusCode: 3, desc: `Pending Customer - Other` },
	{ code: 18, statusCode: 5, desc: `Scheduled Maintenance` },
	{ code: 19, statusCode: 5, desc: `Pending Carrier Response - Ticket` },
	{ code: 20, statusCode: 5, desc: `Unknown Carrier Issue` },
	{ code: 21, statusCode: 6, desc: `In Transit` },
	{ code: 22, statusCode: 6, desc: `Out for Delivery` },
	{ code: 23, statusCode: 2, desc: `Problem Resolved` },
	// { code: 24, statusCode: 1, desc: `Auto-Opened (Low Uptime)` },
	// { code: 25, statusCode: 1, desc: `Auto-Opened (Usage Burst)` },
	// { code: 26, statusCode: 1, desc: `Auto-Opened (Bad RSSI)` },
	// { code: 27, statusCode: 1, desc: `Auto-Opened (Bad EC/IO)` },
	// { code: 28, statusCode: 1, desc: `Auto-Opened (Bouncing)` },
	// { code: 29, statusCode: 1, desc: `Auto-Opened (Fallback)` },
	// { code: 30, statusCode: 1, desc: `Auto-Opened (Latency)` },
	// { code: 31, statusCode: 1, desc: `Auto-Opened (Frame Error Rate)` },
	// { code: 32, statusCode: 2, desc: `Auto-Closed` },
	{ code: 33, statusCode: 5, desc: `Network Outage` },
];
const DIAGNOSES = [
	{ code: 1, desc: "Site" },
	{ code: 2, desc: "Destination" },
	{ code: 3, desc: "Carrier" },
	{ code: 4, desc: "Unit" },
	{ code: 5, desc: "Account" },
	{ code: 6, desc: "Internal" },
];
const SUBDIAGNOSES = [
	{ code: 1, diagnosisCode: 1, desc: `ATM Issue` },
	{ code: 2, diagnosisCode: 1, desc: `Environmental Change` },
	{ code: 3, diagnosisCode: 1, desc: `Location` },
	{ code: 4, diagnosisCode: 2, desc: `Firewall` },
	{ code: 5, diagnosisCode: 2, desc: `Host Configuration` },
	{ code: 6, diagnosisCode: 3, desc: `Capacity Issue` },
	{ code: 7, diagnosisCode: 3, desc: `Network Outage` },
	{ code: 8, diagnosisCode: 3, desc: `Maintenance` },
	{ code: 9, diagnosisCode: 4, desc: `Failed Router` },
	{ code: 10, diagnosisCode: 4, desc: `Antenna / Power Supply` },
	{ code: 11, diagnosisCode: 4, desc: `Incorrect Configuration` },
	{ code: 12, diagnosisCode: 5, desc: `Billing` },
	{ code: 13, diagnosisCode: 5, desc: `Overutilization` },
	{ code: 14, diagnosisCode: 6, desc: `Equipment` },
	{ code: 15, diagnosisCode: 6, desc: `Network Issue` },
	{ code: 16, diagnosisCode: 6, desc: `Ventus Maintenance` },
	{ code: 17, diagnosisCode: 6, desc: `Ventus Outage` },
	{ code: 18, diagnosisCode: 6, desc: `Software Issue` },
	{ code: 19, diagnosisCode: 6, desc: `Outage` },
	{ code: 20, diagnosisCode: 3, desc: `Carrier Configuration` },
];
const SEVERITY_LEVELS = [
	{ code: 1, desc: `Low` },
	{ code: 2, desc: `Medium` },
	{ code: 3, desc: `High` },
];
const RESOLUTION_CODES = [
	{ code: 1, diagnosisCode: 1, desc: `ATM Issue Resolved` },
	{ code: 2, diagnosisCode: 1, desc: `ATM Issue Failed` },
	{ code: 3, diagnosisCode: 1, desc: `Environmental Change Resolved` },
	{ code: 4, diagnosisCode: 1, desc: `Access Issue Resolved` },
	{ code: 5, diagnosisCode: 1, desc: `CPE Unplugged` },
	{ code: 6, diagnosisCode: 1, desc: `Third Party Trouble` },
	{ code: 7, diagnosisCode: 1, desc: `Defective Equipment Resolved` },
	{ code: 8, diagnosisCode: 2, desc: `Firewall Issue Resolved` },
	{ code: 9, diagnosisCode: 2, desc: `Host Configuration Issue Resolved` },
	{ code: 10, diagnosisCode: 3, desc: `Capacity Issue` },
	{ code: 11, diagnosisCode: 3, desc: `Network Outage` },
	{ code: 12, diagnosisCode: 3, desc: `Maintenance` },
	{ code: 13, diagnosisCode: 3, desc: `Ticket Parked / Closed` },
	{ code: 14, diagnosisCode: 3, desc: `Tower Equipment Replaced` },
	{ code: 15, diagnosisCode: 3, desc: `No Trouble Found` },
	{ code: 16, diagnosisCode: 4, desc: `Delivered` },
	{ code: 17, diagnosisCode: 4, desc: `Replacement Completed` },
	{ code: 18, diagnosisCode: 4, desc: `Antenna Issue Resolved` },
	{ code: 19, diagnosisCode: 4, desc: `Power Supply Issue Resolved` },
	{ code: 20, diagnosisCode: 5, desc: `Billing Issue Resolved` },
	{ code: 21, diagnosisCode: 5, desc: `Overutilization Resolved` },
	{ code: 22, diagnosisCode: 6, desc: `Equipment Issue Resolved` },
	{ code: 23, diagnosisCode: 6, desc: `Networks Issue Resolved` },
	{ code: 24, diagnosisCode: 6, desc: `Ventus Maintenance Completed` },
	{ code: 25, diagnosisCode: 6, desc: `Ventus Outage Resolved` },
	{ code: 26, diagnosisCode: 6, desc: `Ventus Outage Resolved` },
	{ code: 43, diagnosisCode: 3, desc: `Update Carrier Configuration` },
	{ code: 44, diagnosisCode: 4, desc: `Corrected Configuration` },
];
const REQUEST_RESOLUTION_CODES = [
	{ code: 27, subtypeCode: 7, desc: `OTA Completed` },
	{ code: 28, subtypeCode: 7, desc: `OTA Failed` },
	{
		code: 29,
		subtypeCode: 8,
		desc: `Comm Check Completed - Results Provided`,
	},
	{ code: 30, subtypeCode: 8, desc: `Comm Check Failed - Unit Down` },
	{ code: 31, subtypeCode: 9, desc: `Installation Completed` },
	{ code: 32, subtypeCode: 9, desc: `Installation Failed` },
	{ code: 33, subtypeCode: 10, desc: `Qualification Passed` },
	{ code: 34, subtypeCode: 10, desc: `Qualification Failed` },
	{ code: 35, subtypeCode: 11, desc: `Site Survey Passed` },
	{ code: 36, subtypeCode: 11, desc: `Site Survey Failed` },
	{ code: 37, subtypeCode: 30, desc: `No Resolution` },
	{ code: 38, subtypeCode: 30, desc: `Tunnel Bounce Completed` },
	{ code: 39, subtypeCode: 30, desc: `Tunnel Bounce Failed` },
	{ code: 40, subtypeCode: 31, desc: `Reconfigured DHCP` },
	{ code: 41, subtypeCode: 31, desc: `Reconfigured Static` },
	{ code: 42, subtypeCode: 31, desc: `Updated Static Configuration` },
];
const TICKET_CATEGORIES = [
	{ type: `open`, display: `Open`, addClass: `ticketing_open` },
	{
		type: `autoOpen`,
		display: `Auto-Opened`,
		addClass: `ticketing_autoOpen`,
	},
	{ type: `closed`, display: `Closed`, addClass: `ticketing_closed` },
	{
		type: `pendCarrier`,
		display: `Pending Carrier`,
		addClass: `ticketing_pendCarrier`,
	},
	{
		type: `pendCustomer`,
		display: `Pending Customer`,
		addClass: `ticketing_pendCustomer`,
	},
	{
		type: `pendShipment`,
		display: `Pending Shipment`,
		addClass: `ticketing_pendShipment`,
	},
	{
		type: `pendVentus`,
		display: `Pending Ventus`,
		addClass: `ticketing_pendVentus`,
	},
	{
		type: `noResponse`,
		display: `No Response`,
		addClass: `ticketing_noResponse`,
	},
];
const ON_SITE_SUPPORT_OPTS = [
	"Diebold",
	"IGT Contractor",
	"IGT FST",
	"NCR",
	"NSA",
	"Broadview",
	"Other",
];
const HOST_LOCATION_OPTIONS = [
	"@Edge",
	"@CT-NW",
	"@UT-SY",
	"@DC6",
	"@DA6",
	"@DCI",
];

// ticket log
const TICKET_LOG_CATEGORY_FILTER_OPTIONS = [
	{ id: "created", text: "Ticket Created", entry_type: "ticket_created" },
	{ id: "modified", text: "Ticket Modified", entry_type: "ticket_modified" },
	{
		id: "attached_cust_request",
		text: "Customer Request",
		entry_type: "cust_request",
	},
	{ id: "attached_note", text: "Note", entry_type: "note" },
	{ id: "attached_unit", text: "Attached Unit", entry_type: "attached_unit" },
	{
		id: "attached_network_object",
		text: "Attached Network Object",
		entry_type: "attached_network_object",
	},
	{
		id: "attached_ticket",
		text: "Sub-Ticket",
		entry_type: "attached_ticket",
	},
	{ id: "attached_file", text: "File", entry_type: "attached_file" },
	{ id: "attached_diag", text: "Diagnostic", entry_type: "diag" },
	{ id: "attached_tcpdump", text: "TCP Capture", entry_type: "tcpdump" },
	{ id: "attached_cil", text: "CIL", entry_type: "cil" },
	{ id: "attached_ota", text: "OTAs", entry_type: "ota" },
	{
		id: "attached_remote_command",
		text: "Remote Command",
		entry_type: "remote_command",
	},
	{ id: "attached_celox_site", text: "Celox Site", entry_type: "celox_site" },
	{
		id: "attached_celox_module",
		text: "Celox Module",
		entry_type: "celox_module",
	},
];
const TICKET_LOG_DEFAULT_CATEGORIES = [
	"created",
	"modified",
	"attached_celox_module",
	"attached_celox_site",
	"attached_cil",
	"attached_cust_request",
	"attached_diag",
	"attached_file",
	"attached_note",
	"attached_ota",
	"attached_remote_command",
	"attached_tcpdump",
	"attached_ticket",
	"attached_unit",
	"attached_network_object",
];

export {
	TICKET_TYPES,
	TICKET_SUBTYPES,
	TICKET_STATUSES,
	TICKET_DISABLED_SECONDARY_STATUSES,
	TICKET_SECONDARY_STATUSES,
	DIAGNOSES,
	SUBDIAGNOSES,
	SEVERITY_LEVELS,
	RESOLUTION_CODES,
	REQUEST_RESOLUTION_CODES,
	TICKET_CATEGORIES,
	ON_SITE_SUPPORT_OPTS,
	HOST_LOCATION_OPTIONS,
	TICKET_LOG_CATEGORY_FILTER_OPTIONS,
	TICKET_LOG_DEFAULT_CATEGORIES,
};
