<template>
	<v-dialog
		content-class="global-search-dialog"
		width="800px"
		height="100vh"
		transition="scroll-y-transition"
	>
		<v-card height="100%">
			<v-card-title>
				Global Search
				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-btn
							icon
							flat
							v-bind="props"
							@click="showGlobalSearchTerms = !showGlobalSearchTerms"
						>
							<v-icon :color="showGlobalSearchTerms ? 'primary' : ''">
								fa-light fa-bars-staggered
							</v-icon>
						</v-btn>
					</template>
					<span>
						{{ showGlobalSearchTerms ? "Hide" : "Show" }}
						search fields
					</span>
				</v-tooltip>
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="3">
						<v-tabs
							:direction="$vuetify.display.xs ? 'horizontal' : 'vertical'"
							:show-arrows="$vuetify.display.xs"
						>
							<v-tab
								v-for="item in items"
								:key="item.title"
								:class="item.class"
								:color="item.color"
							>
								<v-icon start>
									{{ item.icon }}
								</v-icon>
								{{ item.title }}
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col cols="12" sm="9">
						<v-row>
							<v-col>
								<VAutocompleteWithValidation
									v-if="showGlobalSearchTerms"
									v-model="globalSearchTerms"
									:items="globalSearchTermList"
									item-title="label"
									item-value="field"
									label="Search By..."
									variant="outlined"
									color="default"
									return-object
									multiple
									chips
									closable-chips
									clearable
									placeholder="Select an option"
								>
									<template #chip="{ item, props }">
										<v-chip size="small" :color="item.raw.color" v-bind="props">
											<template #prepend>
												<v-icon
													:icon="item.raw.icon"
													size="small"
													class="mr-2"
												></v-icon>
											</template>
											{{ item.raw.label }}
										</v-chip>
									</template>
								</VAutocompleteWithValidation>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<GlobalSearchInput
									v-model="result"
									return-object
									autofocus
									:is-global-search="true"
									:search-types="searchTypes"
									:search-fields="searchFields"
									:item-disabled="(item) => item.customHeader"
									placeholder="Search by unit, ticket, module, network object or multi-site..."
									:menu-props="{
										maxHeight: menuMaxHeight,
										maxWidth: menuMaxWidth,
										contentClass: 'TheGlobalSearchContent',
									}"
									@update:model-value="selectItem"
								/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
const VALID_GLOBAL_SEARCH_LIST = [
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Hostname",
		field: "hostname",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Serial",
		field: "serial",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Router IP",
		field: "router_ip",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Router Alias",
		field: "router_alias",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Site Address",
		field: "site_addr",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "Device IP",
		field: "addresses",
	},
	{
		type: "unit",
		color: "typeUnit",
		icon: "fa-solid fa-router",
		label: "IP Addr. Alias",
		field: "aliases",
	},
	{
		type: "module",
		color: "typeModule",
		icon: "fa-solid fa-microchip",
		label: "MDN",
		field: "mdn",
	},
	{
		type: "module",
		color: "typeModule",
		icon: "fa-solid fa-microchip",
		label: "SIM",
		field: "sim_card_id",
	},
	{
		type: "ticket",
		color: "typeTicket",
		icon: "fa-solid fa-ticket",
		label: "Ticket Name",
		field: "ticket",
	},
	{
		type: "network_object",
		color: "typeNetwork",
		icon: "fa-solid fa-network-wired",
		label: "Network Object Name",
		field: "network_object",
	},
	{
		type: "multi_site",
		color: "typeMultisite",
		icon: "fa-solid fa-chart-network",
		label: "Multi Site Name",
		field: "multi_site",
	},
];

export default {
	emits: ["navigatedFromSearchResult"],
	data() {
		return {
			typeTab: null,
			result: null,
			showGlobalSearchTerms: false,
			globalSearchTerms: VALID_GLOBAL_SEARCH_LIST,
			globalSearchTermList: VALID_GLOBAL_SEARCH_LIST,
			items: [
				{
					title: "Unit",
					icon: "fa-solid fa-router",
					color: "typeUnit",
				},
				{
					title: "Ticket",
					icon: "fa-solid fa-ticket",
					color: "typeTicket",
				},
				{
					title: "Module",
					icon: "fa-solid fa-microchip",
					color: "typeModule",
				},
				{
					title: "Network",
					icon: "fa-solid fa-network-wired",
					color: "typeNetwork",
				},
				{
					title: "Multi Site ",
					icon: "fa-solid fa-chart-network",
					color: "typeMultisite",
				},
			],
		};
	},
	computed: {
		searchTypes() {
			//using Sets allows for unique array of objects
			return [...new Set(this.globalSearchTerms.map((item) => item.type))];
		},
		searchFields() {
			//using Sets allows for unique array of objects
			return [...new Set(this.globalSearchTerms.map((item) => item.field))];
		},
		menuMaxHeight() {
			const vh = Math.max(
				document.documentElement.clientHeight || 0,
				window.innerHeight || 0,
			);
			return vh - 280;
		},
		menuMaxWidth() {
			const vw = Math.max(
				document.documentElement.clientWidth || 0,
				window.innerWidth || 0,
			);
			return vw - 100;
		},
	},
	methods: {
		selectItem(value) {
			if (value) {
				if (value.category === "Unit") {
					this.$router.push({
						name: "units-hostname-info",
						params: { hostname: value.ref.hostname },
					});
					this.$emit("navigatedFromSearchResult");
				} else if (value.category === "Ticket") {
					this.$router.push({
						name: "tickets-id",
						params: { ticketId: value.ref.idTicket },
					});
					this.$emit("navigatedFromSearchResult");
				} else if (value.category === "Module") {
					if (
						this.$route.name !== "units-hostname-info" ||
						(this.$route.params.hostname &&
							this.$route.params.hostname !== value.ref.hostname)
					) {
						this.$router.push({
							name: "units-hostname-info",
							params: { hostname: value.ref.hostname },
						});
						this.$emit("navigatedFromSearchResult");
					}
				} else if (value.category === "Network Object") {
					this.$router.push({
						name: "network-objects-id-info",
						params: { networkObjectId: value.ref.id },
					});
					this.$emit("navigatedFromSearchResult");
				} else if (value.category === "Multi-Site") {
					// this.$router.push({
					// 	name: "device-groups-id-info",
					// 	params: { id: value.ref.id },
					// });
					// this.$emit("navigatedFromSearchResult");
				}
			}
		},
		getUnitStatusRender(status) {
			const temp = {};
			if (status === 0) {
				temp.color = "error";
				temp.icon = "fa-solid fa-down";
			} else if (status === 1) {
				temp.color = "success";
				temp.icon = "fa-solid fa-up";
			} else if (status === 2) {
				temp.color = "orange";
				temp.icon = "fa-solid fa-down";
			} else {
				temp.color = "";
				temp.icon = "fa-solid fa-question";
			}
			return temp;
		},
		getPrependListIcon(type) {
			if (type === "Unit") {
				return "fa-solid fa-router";
			} else if (type === "Ticket") {
				return "fa-solid fa-ticket";
			} else if (type === "Module") {
				return "fa-solid fa-microchip";
			} else if (type === "Network Object") {
				return "fa-solid fa-network-wired";
			} else if (type === "Multi-Site") {
				return "fa-solid fa-chart-network";
			} else {
				return "";
			}
		},
	},
};
</script>

<style scoped lang="scss">
:global(.v-overlay__content.TheGlobalSearchContent > .v-list) {
	box-shadow: none;
}
</style>
