const ROUTES = [
	{
		path: "",
		name: "sites",
		redirect: { name: "sites-qualifications-pending" },
	},
	{
		path: "qualifications/request",
		name: "sites-qualifications-request",
		component: () => import("./SitesQualificationsRequest.vue"),
		meta: {
			requiresPriv: "canAccessQualifications",
			includeInNav: false,
			navTitle: "Request Qualification",
			pageTitle: "Request Qualifications",
		},
	},
	{
		path: "qualifications/pending",
		name: "sites-qualifications-pending",
		component: () => import("./SitesQualificationsPending.vue"),
		meta: {
			requiresPriv: "canAccessQualifications",
			includeInNav: true,
			navTitle: "Pending Qualifications",
			pageTitle: "Pending Qualifications",
		},
	},
	{
		path: "qualifications/history",
		name: "sites-qualifications-history",
		component: () => import("./SitesQualificationsHistory.vue"),
		meta: {
			requiresPriv: "canAccessQualifications",
			includeInNav: true,
			navTitle: "Qualification History",
			pageTitle: "Qualification History",
		},
	},
	// {
	// 	path: "qualifications/statistics",
	// 	name: "sites-qualifications-statistics",
	// 	component: () => import("./SitesQualificationsStatistics.vue"),
	// 	meta: {
	// 		requiresPriv: "canAccessQualificationStats",
	// 		includeInNav: true,
	// 		navTitle: "Qualification Statistics",
	// 		pageTitle: "Qualification Statistics",
	// 	},
	// },
	{
		path: "qualifications/:qualificationId/complete",
		name: "sites-qualifications-id-complete",
		component: () => import("./SitesQualificationsIdComplete.vue"),
		props: true,
		meta: {
			requiresPriv: "canAccessQualifications",
			includeInNav: false,
		},
		beforeEnter: (to, from, next) => {
			if (to.params && to.params.qualificationId) {
				to.meta.pageTitle = `Site Qualification: ${to.params.qualificationId} - Complete`;
				next();
			} else {
				next();
			}
		},
	},
	{
		path: "surveys/pending",
		name: "sites-surveys-pending",
		component: () => import("./SitesSurveysPending.vue"),
		meta: {
			requiresPriv: "canAccessSurveys",
			includeInNav: true,
			navTitle: "Pending Surveys",
			pageTitle: "Pending Surveys",
		},
	},
	{
		path: "surveys/history",
		name: "sites-surveys-history",
		component: () => import("./SitesSurveysHistory.vue"),
		meta: {
			requiresPriv: "canAccessSurveys",
			includeInNav: true,
			navTitle: "Survey History",
			pageTitle: "Survey History",
		},
	},
];

export default {
	path: "/sites",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessSites",
		navTitle: "Sites",
		pageTitle: "Sites",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
