<template>
	<div v-show="hasAnyItems" class="dock-main">
		<div class="dock-groups-panel">
			<keep-alive>
				<component :is="selectedGroup" :theme="vuetifyTheme"></component>
			</keep-alive>
		</div>
		<div class="dock-groups">
			<v-tabs
				v-if="$vuetify.display.mdAndUp"
				v-model="tab"
				:mandatory="false"
				bg-color="surface"
				height="35"
			>
				<template v-for="item in dockItems" :key="item.tabTitle">
					<v-tab
						v-if="item.show"
						@click.stop="selectedDockGroup(item.componentName)"
					>
						{{ item.tabTitle }}
						<v-chip size="small" class="ml-2 dock-chip" color="primary">
							{{ item.count }}
						</v-chip>
					</v-tab>
				</template>
			</v-tabs>
			<div v-if="$vuetify.display.smAndDown" class="dock-minimized">
				<v-menu offset="5">
					<template #activator="{ props }">
						<v-btn color="surface" v-bind="props">
							Dock
							<template #append>
								<v-chip size="small" color="primary">
									{{ dockTotalCount }}
								</v-chip>
							</template>
						</v-btn>
					</template>
					<v-list>
						<template v-for="item in dockItems" :key="item.tabTitle">
							<v-list-item
								v-if="item.show"
								:style="item.routeName ? 'cursor: pointer' : 'cursor: default'"
								@click="
									item.routeName
										? $router.push({ name: item.routeName })
										: () => {}
								"
							>
								<v-list-item-title>{{ item.tabTitle }}</v-list-item-title>
								<template #append>
									<v-chip size="small" color="primary" class="ml-4">
										{{ item.count }}
									</v-chip>
								</template>
							</v-list-item>
						</template>
					</v-list>
				</v-menu>
			</div>
		</div>
	</div>
</template>

<script>
import { useDockStore } from "@/stores/store.dock";
import { useUserStore } from "@/stores/store.user";

let dockInterval;

export default {
	components: {
		DockAlerts: defineAsyncComponent(
			() => import("@/components/Dock/DockAlerts.vue"),
		),
		DockWarnings: defineAsyncComponent(
			() => import("@/components/Dock/DockWarnings.vue"),
		),
		DockNotifications: defineAsyncComponent(
			() => import("@/components/Dock/DockNotifications.vue"),
		),
		DockDiagnostics: defineAsyncComponent(
			() => import("@/components/Dock/DockDiagnostics.vue"),
		),
		DockTcpCaptures: defineAsyncComponent(
			() => import("@/components/Dock/DockTcpCaptures.vue"),
		),
		DockOtas: defineAsyncComponent(
			() => import("@/components/Dock/DockOtas.vue"),
		),
		DockReports: defineAsyncComponent(
			() => import("@/components/Dock/DockReports.vue"),
		),
		DockInstallations: defineAsyncComponent(
			() => import("@/components/Dock/DockInstallations.vue"),
		),
	},
	data() {
		return {
			tab: null,
			selectedGroup: null,
		};
	},
	computed: {
		...mapState(useUserStore, ["vuetifyTheme", "prefs"]),
		...mapState(useDockStore, {
			hasAnyItems: "hasAnyItems",
			dockAlertsCount: (store) => store.alerts.internal.length,
			dockWarningsCount: (store) => store.alerts.service.length,
			dockReportsCount: (store) => store.reports.length,
			dockDiagnosticsCount: (store) => store.diagnostics.length,
			dockOtasCount: (store) => store.otas.length,
			dockInstallationsCount: (store) => store.installations.length,
			dockNotificationsCount: (store) => store.notifications.length,
			dockTcpCapturesCount: (store) => store.tcpdumps.length,
			dockItems() {
				const temp = [
					{
						tabTitle: "Alerts",
						componentName: "DockAlerts",
						show: this.prefs["Dock_showAlerts"],
						count: this.dockAlertsCount,
					},
					{
						tabTitle: "Warnings",
						componentName: "DockWarnings",
						show: this.prefs["Dock_showWarnings"],
						count: this.dockWarningsCount,
					},
					{
						tabTitle: "Notifications",
						componentName: "DockNotifications",
						show: this.prefs["Dock_showNotifications"],
						count: this.dockNotificationsCount,
						routeName: "notifications-history",
					},
					{
						tabTitle: "Diagnostics",
						componentName: "DockDiagnostics",
						show: this.prefs["Dock_showDiagnostics"],
						count: this.dockDiagnosticsCount,
						routeName: "diagnostics-history",
					},
					{
						tabTitle: "Tcp Captures",
						componentName: "DockTcpCaptures",
						show: this.prefs["Dock_showTCPCaptures"],
						count: this.dockTcpCapturesCount,
						routeName: "diagnostics-tcp-capture-history",
					},
					{
						tabTitle: "OTAs",
						componentName: "DockOtas",
						show: this.prefs["Dock_showOTAs"],
						count: this.dockOtasCount,
						routeName: "otas-history",
					},
					{
						tabTitle: "Reports",
						componentName: "DockReports",
						show: this.prefs["Dock_showReports"],
						routeName: "reporting-history",
						count: this.dockReportsCount,
					},
					{
						tabTitle: "Installations",
						componentName: "DockInstallations",
						show: this.prefs["Dock_showInstallations"],
						count: this.dockInstallationsCount,
						routeName: "installations-history",
					},
				];
				return temp.filter((item) => item.count);
			},
		}),
		dockTotalCount() {
			return (
				this.dockAlertsCount +
				this.dockWarningsCount +
				this.dockReportsCount +
				this.dockDiagnosticsCount +
				this.dockOtasCount +
				this.dockInstallationsCount +
				this.dockNotificationsCount +
				this.dockTcpCapturesCount
			);
		},
	},
	created() {
		const vm = this;
		vm.getDockInfo();

		dockInterval = setInterval(() => {
			vm.getDockInfo();
		}, 10000);
	},
	mounted() {
		const vm = this;
		window.addEventListener("click", function (e) {
			if (
				document.getElementsByClassName("dock-groups-panel").length &&
				!document
					.getElementsByClassName("dock-groups-panel")[0]
					.contains(e.target)
			) {
				if (vm.selectedGroup) {
					vm.tab = null;
					vm.selectedGroup = null;
				}
			}
		});
	},
	unmounted() {
		clearInterval(dockInterval);
	},
	methods: {
		...mapActions(useDockStore, ["getDockInfo"]),
		selectedDockGroup(group) {
			if (group !== this.selectedGroup) {
				this.selectedGroup = group;
			} else {
				this.selectedGroup = null;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.dock-groups {
	bottom: 0px;
	left: 0px;
	position: fixed;
	z-index: 10;
}
.dock-groups-panel {
	max-height: 500px;
	overflow: auto;
	position: fixed;
	bottom: 40px;
	left: 0px;
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	z-index: 10;
}
.dock-minimized {
	margin-left: 12px;
}

.menu__item {
	all: unset;
	flex-grow: 1;
	z-index: 100;
	display: flex;
	cursor: pointer;
	font-size: 0.8em;
	position: relative;
	border-radius: 50%;
	align-items: center;
	will-change: transform;
	justify-content: center;
	padding: 0.55em 0 0.85em;
	transition: transform var(--timeOut, var(--duration));
}

.menu__item::before {
	content: "";
	z-index: -1;
	width: 4.2em;
	height: 4.2em;
	border-radius: 50%;
	position: absolute;
	transform: scale(0);
	transition:
		background-color var(--duration),
		transform var(--duration);
}

.menu__item.active {
	transform: translate3d(0, -0.8em, 0);
}

.menu__item.active::before {
	transform: scale(1);
	background-color: var(--bgColorItem);
}

.icon {
	width: 2.6em;
	height: 2.6em;

	box-sizing: border-box;
	stroke: white;
	fill: transparent;
	stroke-width: 18pt;
	stroke-miterlimit: 10;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 2000;
}

.menu__item.active .icon {
	animation: strok 1.5s reverse;
}

@keyframes strok {
	100% {
		stroke-dashoffset: 2000;
	}
}

.menu__border {
	left: 0;
	bottom: 99%;
	width: 10.9em;
	height: 2.4em;
	position: absolute;
	clip-path: url(#menu);
	will-change: transform;
	background-color: rgb(var(--v-theme-surface-lighten-1));
	transition: transform var(--timeOut, var(--duration));
}

.svg-container {
	width: 0;
	height: 0;
}
</style>
