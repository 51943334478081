<template>
	<v-skeleton-loader
		v-if="loading"
		type="list-item-two-line"
	></v-skeleton-loader>
	<v-autocomplete
		v-else
		v-model="value"
		:name="name"
		:items="updatedItems"
		:variant="$attrs.variant ? $attrs.variant : 'underlined'"
		:color="$attrs.color ? $attrs.color : 'primary'"
		:error-messages="errors"
		autocomplete="off"
	>
		<template
			v-for="(_, slotName) in $slots"
			:key="slotName"
			#[slotName]="slotProps"
		>
			<slot :name="slotName" v-bind="slotProps || {}" />
		</template>
	</v-autocomplete>
</template>

<script>
import { nanoid } from "nanoid";
import { useField } from "vee-validate";
import { useUserStore } from "@/stores/store.user";

export default {
	name: "VAutocompleteWithValidation",
	props: {
		// eslint-disable-next-line vue/require-default-prop
		modelValue: {
			type: [String, Number, Boolean, Array, Object, Date, Function],
		},
		name: {
			type: String,
			default: function () {
				const uid = nanoid();
				return `VAutocompleteWithValidation-${uid}`;
			},
		},
		items: {
			type: Array,
			default: () => [],
		},
		// Lookup endpoint to prefill
		// see: http://wiki.ventusnetworks.com/mediawiki/index.php/Genesis_Lookup
		lookup: {
			type: String,
			default: "",
		},
		lookupParams: {
			type: Object,
			default: () => {},
		},
		// If endpoint is not a lookup, probably will have to specify
		// item-text & item-value props
		getUrl: {
			type: String,
			default: "",
		},
		getUrlParams: {
			type: Object,
			default: () => {},
		},
		getUrlKey: {
			type: String,
			default: "",
		},
		//smartlist access speciial case filter
		smartlistForAccess: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["update:modelValue", "lookupItems"],
	setup(props) {
		const { value, errors, resetField } = useField(
			toRef(props, "name"),
			undefined,
			{
				initialValue: toRef(props, "modelValue"),
				syncVModel: false,
			},
		);

		return {
			value,
			errors,
			resetField,
		};
	},
	data() {
		return {
			loading: false,
			updatedItems: this.items,
		};
	},
	computed: {
		...mapState(useUserStore, ["customer_id"]),
	},
	watch: {
		value: function (newVal) {
			//in the case of reset through <Form>
			//resetForm from <Form> sets to undefined
			//using that hook to call resetField
			if (typeof newVal === "undefined") {
				this.resetField();
			} else if (this.modelValue !== newVal) {
				this.$emit("update:modelValue", newVal);
			}
		},
		modelValue: function (newVal) {
			if (this.value !== newVal) {
				this.value = newVal;
				this.$emit("update:modelValue", newVal);
			}
		},
		// Necessary for when the item list is updated in the parent
		items: function (newItems) {
			this.updatedItems = newItems;
		},
		// Necessary for when the get url params are updated in the parent
		getUrlParams: function (params) {
			this.fetchUrlEndpoint(params);
		},
		lookupParams: function (params) {
			this.getNormalLookup(params);
		},
	},
	created() {
		let tempPost = {};
		if (this.lookup) {
			//special case for solution post key
			//maybe get dan to change this
			if (this.lookup === "solution") {
				if (this.lookupParams) {
					tempPost = this.lookupParams;
				} else if ($userHasPriv("hasInternalAccess")) {
					tempPost.customer = "all";
				} else {
					tempPost.customer = this.customer_id;
				}
			}
			//only active invoiced if user is customer
			else if (this.lookup === "equipment_order_status") {
				if ($userHasPriv("hasCustomerAccess")) {
					tempPost.active_invoice = 1;
				}
			} else {
				tempPost = this.lookupParams;
			}
			this.getNormalLookup(tempPost);
		} else if (this.getUrl) {
			this.fetchUrlEndpoint(this.getUrlParams);
		}
	},
	methods: {
		getNormalLookup(params) {
			this.loading = true;
			return $http
				.get(`lookup/${this.lookup}`, { params })
				.then((response) => {
					if (response && response[this.lookup]) {
						//special case for adding visible status for internal users
						if (this.lookup === "equipment_order_status") {
							this.updatedItems = response[this.lookup].map((item) => {
								item.label = $userHasPriv("hasInternalAccess")
									? item.label +
										(item.active_invoice ? " - Active" : " - Inactive")
									: item.label;
								return item;
							});
						} else if (this.$attrs.id === "vrf_id_dt_filter") {
							this.updatedItems = response[this.lookup].filter(
								(vrf) => vrf.id !== 1001 && vrf.id !== 1000,
							);
						} else {
							this.updatedItems = response[this.lookup];
							//special case because of first item being null
							//dan needs to update this lookup
							if (this.lookup === "router_sequence_no") {
								this.updatedItems.shift();
							}
						}
					}

					this.$emit("lookupItems", this.updatedItems);
				})
				.catch((err) => console.error("lookup failed", err))
				.finally(() => (this.loading = false));
		},
		fetchUrlEndpoint(params) {
			this.loading = true;
			$http
				.get(`${this.getUrl}`, { params })
				.then((response) => {
					if (response) {
						this.updatedItems = this.getUrlKey
							? response[this.getUrlKey]
							: response;

						if (this.smartlistForAccess) {
							this.updatedItems = this.updatedItems.filter(
								(item) => item.for_access,
							);
						}
					}

					this.$emit("lookupItems", this.updatedItems);
				})
				.catch((err) => console.error("getUrl failed", err))
				.finally(() => (this.loading = false));
		},
	},
};
</script>
