<template>
	<v-dialog persistent max-width="700">
		<v-card>
			<DialogHeader
				title="Preferences"
				@close-dialog="$emit('update:modelValue', false)"
			>
			</DialogHeader>

			<v-card-text>
				<v-row>
					<!-- dock -->
					<v-col cols="12">
						<h3>Dock Settings</h3>
						<v-row no-gutters>
							<v-col
								v-for="item in dockList"
								:key="item.id"
								cols="auto"
								class="mr-2"
							>
								<VCheckboxWithValidation
									v-model="item.checked"
									:label="item.label"
									density="compact"
									hide-details
									@update:model-value="updatePreference(item.checked, item.id)"
								></VCheckboxWithValidation>
							</v-col>
						</v-row>
					</v-col>
					<!-- landing page -->
					<v-col cols="12">
						<h3>Landing Page</h3>
						<VAutocompleteWithValidation
							v-model="landingPage"
							:items="landingPageOptionsFiltered"
							item-title="label"
							item-value="value"
							placeholder="Select a landing page"
							@update:model-value="
								updatePreference(landingPage, 'router_landing_page_v3')
							"
						/>
					</v-col>
					<!-- temperature -->
					<v-col cols="6">
						<h3>Temperature Metric</h3>
						<VRadioGroupWithValidation
							v-model="temperatureMetric"
							inline
							hide-details
							@update:model-value="
								updatePreference(temperatureMetric, 'temperature_unit')
							"
						>
							<template v-for="item in temperatureOptions" :key="item.value">
								<VRadioWithValidation
									:label="item.label"
									:value="item.value"
								></VRadioWithValidation>
							</template>
						</VRadioGroupWithValidation>
					</v-col>
					<!-- time zone -->
					<v-col cols="6">
						<h3>Time Zone</h3>
						<VAutocompleteWithValidation
							v-model="timeZone"
							:items="timeZoneOptions"
							item-title="label"
							item-value="value"
							hide-details
							density="compact"
							@update:model-value="updatePreference(timeZone, 'time_zone')"
						/>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { useUserStore } from "@/stores/store.user";

export default {
	name: "ThePreferences",
	emits: ["update:modelValue"],
	setup() {
		const userStore = useUserStore();
		return { userStore };
	},
	data() {
		return {
			temperatureMetric: this.userStore.prefs.temperature_unit,
			temperatureOptions: [
				{ label: "Farenheit", value: "farenheit" },
				{ label: "Celsius", value: "celsius" },
			],
			timeZone: this.userStore.prefs.time_zone,
			timeZoneOptions: [
				{ label: "Central US (−06:00 / DST -05:00)", value: "America/Chicago" },
				{
					label: "Eastern US (−05:00 / DST -04:00)",
					value: "America/New_York",
				},
				{ label: "Mountain US (−07:00 / DST -06:00)", value: "America/Denver" },
				{
					label: "Western US (−08:00 / DST -07:00)",
					value: "America/Los_Angeles",
				},
				{
					label: "Greenwich Mean Time (+00:00 / DST +01:00)",
					value: "Europe/Dublin",
				},
				{
					label: "Central Europe Time (+01:00 / DST +02:00)",
					value: "Europe/Luxembourg",
				},
			],
			landingPage: this.userStore.prefs.router_landing_page_v3,
			landingPageOptions: [
				{
					label: "Home Page",
					value: "/",
					requiredAuth: false,
				},
				{
					label: "Address Correction",
					value: "/address-correction",
					requiredAuth: ["canCorrectAddresses"],
				},
				{
					label: "Cellular",
					value: "/cellular",
					requiredAuth: ["can_view_cellular_plans"],
				},
				{
					label: "Customer Dash",
					value: "/customer-dash",
				},
				{
					label: "Diagnostics",
					value: "/diagnostics",
					requiredAuth: ["canRunDiagnostics"],
				},
				{
					label: "Engineering",
					value: "/engineering",
					requiredAuth: ["canAccessEngineering"],
				},
				{
					label: "Installations",
					value: "/installations",
					requiredAuth: [
						"canViewInstallations",
						"canViewInstallationTemplates",
					],
				},
				{
					label: "Map",
					value: "/map",
					requiredAuth: false,
				},
				{
					label: "Monitoring",
					value: "/monitoring",
					requiredAuth: ["canAccessMonitoring"],
				},
				{
					label: "Network",
					value: "/network",
					requiredAuth: ["canViewNetwork"],
				},
				{
					label: "Network Objects",
					value: "/network-objects",
					requiredAuth: ["canAccessNetworkObjects"],
				},
				{
					label: "Notifications",
					value: "/notifications",
					requiredAuth: false,
				},
				// {
				// 	label: "Ordering",
				// 	value: "/ordering",
				// 	requiredAuth: ["canAccessOrdering"],
				// },
				{
					label: "OTAs",
					value: "/otas",
					requiredAuth: ["canAccessOTAs"],
				},
				{
					label: "Reporting",
					value: "/reporting",
					requiredAuth: ["canAccessReporting"],
				},
				// {
				// 	label: "Scheduling",
				// 	value: "/scheduling",
				// 	requiredAuth: ["canAccessScheduling"],
				// },
				{
					// 'and surveys', when implemented
					label: "Site Qualifications",
					value: "/sites",
					requiredAuth: ["canAccessSites"],
				},
				{
					label: "SmartLists",
					value: "/smartlists",
					requiredAuth: ["canViewSmartLists"],
				},
				{
					label: "Tickets",
					value: "/tickets",
					requiredAuth: ["canAccessTickets"],
				},
				{
					label: "TSC",
					value: "/tsc",
					requiredAuth: ["canAccessTSC"],
				},
				{
					label: "Units",
					value: "/units",
					requiredAuth: ["canAccessUnits"],
				},
				{
					label: "Usage",
					value: "/usage",
					requiredAuth: ["canAccessUsage"],
				},
				{
					label: "User Management",
					value: "/users",
					requiredAuth: ["canAccessUsers"],
				},
			],
			dockList: [
				{
					id: "Dock_showAlerts",
					label: "Show Internal Alerts",
					checked: this.userStore.prefs.Dock_showAlerts,
				},
				{
					id: "Dock_showWarnings",
					label: "Show Service Warnings",
					checked: this.userStore.prefs.Dock_showWarnings,
				},
				{
					id: "Dock_showNotifications",
					label: "Show Notifications",
					checked: this.userStore.prefs.Dock_showNotifications,
				},
				{
					id: "Dock_showDiagnostics",
					label: "Show Diagnostics",
					checked: this.userStore.prefs.Dock_showDiagnostics,
				},
				{
					id: "Dock_showOTAs",
					label: "Show OTAs",
					checked: this.userStore.prefs.Dock_showOTAs,
				},
				{
					id: "Dock_showReports",
					label: "Show Reports",
					checked: this.userStore.prefs.Dock_showReports,
				},
				{
					id: "Dock_showInstallations",
					label: "Show Installations",
					checked: this.userStore.prefs.Dock_showInstallations,
				},
				{
					id: "Dock_showTCPCaptures",
					label: "Show TCP Captures",
					checked: this.userStore.prefs.Dock_showTCPCaptures,
				},
				// probably do not need these anymore, depends on the new design
				// {
				// 	id: "Dock_showStatusCounts",
				// 	label: "Show Status Counts",
				// 	checked: this.userStore.prefs.Dock_showStatusCounts,
				// },
				// {
				// 	id: "Dock_isLarge",
				// 	label: "Show Large Dock",
				// 	checked: this.userStore.prefs.Dock_isLarge,
				// },
				// {
				// 	id: "Nav_isLarge",
				// 	label: "Show Large Nav",
				// 	checked: this.userStore.prefs.Nav_isLarge,
				// },
			],
		};
	},
	computed: {
		landingPageOptionsFiltered() {
			return this.landingPageOptions.filter(
				(item) =>
					!item.requiredAuth ||
					(item.requiredAuth && $userHasPriv(item.requiredAuth, false)),
			);
		},
	},
	methods: {
		...mapActions(useUserStore, ["updateUserPrefs"]),
		updatePreference(bool, id) {
			let post = {};
			post[id] = bool;

			return this.updateUserPrefs(post)
				.then((response) => {
					$toast.success("Preference succesesfully updated!", {
						timeout: 1000,
					});
					return response;
				})
				.catch((err) => console.error("updateUserPrefs failed", err));
		},
	},
};
</script>
