// Vuetify Config
import "vuetify/styles";
import "@/styles/main.scss";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa";
import * as labs from "vuetify/labs/components";
import MomentAdapter from "@date-io/moment";

const nonceElem = document.querySelector("meta[property='csp-nonce']");
const nonce = nonceElem && nonceElem.nonce;

const moment = new MomentAdapter();

const GenesisLightTheme = {
	dark: false,
	colors: {
		primary: "#05AAEC",
		secondary: "#ec881d",
		brand: "#7CBB60",
		background: "#f2f3f8",
		optionA: "#524B80",
		optionB: "#4B7A80",
		accent: "#9400ea",
		accentAlt: "#3a21ce",
		highlight: "#0BDBD8",
		error: "#f44336",
		warning: "#ffc107",
		info: "#2196f3",
		instr: "#9C26B0",
		success: "#4caf50",
		primaryText: "#000000",
		secondaryText: "#ffffff",
		upGreen: "#00aa00",
		downRed: "#cb4943",
		softdownOrange: "#d3650e",
		warningYellow: "#ddbb00",
		idle: "#279bd6",
		orphan: "#0093d8",
		powerPlug: "#94e61a",
		powerDown: "#908787",
		powerBattery: "#05c0f7",
		failoverFalse: "#000000",
		failoverTrue: "#b1daff",
		ticketFalse: "#000000",
		ticketTrue: "#000000",
		typeUnit: "#fba850",
		typeTicket: "#85b52b",
		typeModule: "#e63f45",
		typeNetwork: "#00abe0",
		typeMultisite: "#a274f1",
		typeAlert: "#ff004e",
		typeWarning: "#ffb100",
		typeDiagnostic: "#00ff4e",
		typeInstallation: "#0062ff",
		customerHighlight: "#d576ef",
		stonlyPink: "#ff4a75",
	},
};

const GenesisDarkTheme = {
	dark: true,
	colors: {
		primary: "#00b4f1",
		secondary: "#ec881d",
		brand: "#7CBB60",
		background: "#121212",
		optionA: "#524B80",
		optionB: "#4B7A80",
		accent: "#9400ea",
		accentAlt: "#3a21ce",
		highlight: "#e3ff00",
		error: "#c8493e",
		warning: "#ffc107",
		info: "#2196f3",
		instr: "#9C26B0",
		success: "#79d053",
		primaryText: "#ffffff",
		secondaryText: "#000000",
		upGreen: "#00aa00",
		downRed: "#cb4943",
		softdownOrange: "#d3650e",
		warningYellow: "#ddbb00",
		idle: "#279bd6",
		orphan: "#0093d8",
		powerPlug: "#94e61a",
		powerDown: "#908787",
		powerBattery: "#05c0f7",
		failoverFalse: "#ffffff",
		failoverTrue: "#b1daff",
		ticketFalse: "#ffffff",
		ticketTrue: "#ffffff",
		typeUnit: "#fba850",
		typeTicket: "#85b52b",
		typeModule: "#e63f45",
		typeNetwork: "#00abe0",
		typeMultisite: "#a274f1",
		typeAlert: "#ff004e",
		typeWarning: "#ffb100",
		typeDiagnostic: "#00ff4e",
		typeInstallation: "#0062ff",
		customerHighlight: "#d576ef",
		stonlyPink: "#ff4a75",
	},
};

export default createVuetify({
	components: {
		...labs,
	},
	display: {
		mobileBreakpoint: "md",
	},
	theme: {
		cspNonce: nonce,
		defaultTheme: "GenesisDarkTheme",
		variations: {
			colors: [
				"surface",
				"background",
				"brand",
				"primary",
				"secondary",
				"accent",
				"accentAlt",
				"highlight",
				"optionA",
				"optionB",
				"error",
				"warning",
				"info",
				"instr",
				"success",
			],
			lighten: 4,
			darken: 4,
		},
		themes: {
			GenesisDarkTheme,
			GenesisLightTheme,
		},
	},
	icons: {
		defaultSet: "fa",
		aliases: {
			...aliases,
			warning: "fa-solid fa-triangle-exclamation",
			error: "fa-solid fa-circle-xmark",
		},
		sets: {
			fa,
		},
	},
	date: {
		adapter: moment,
	},
});
