const ROUTES = [
	{
		path: "",
		name: "engineering",
		redirect: { name: "engineering-ip-management" },
	},
	{
		path: "ip-management",
		name: "engineering-ip-management",
		component: () => import("./EngineeringIpManagement.vue"),
		meta: {
			requiresPriv: "canAccessIPMan",
			includeInNav: true,
			navTitle: "IP Management",
			pageTitle: "Engineering IP Management",
		},
	},
	{
		path: "ip-nat",
		name: "engineering-ip-nat",
		component: () => import("./EngineeringIpNat.vue"),
		meta: {
			requiresPriv: "canUpdateNATAddress",
			includeInNav: true,
			navTitle: "NAT IP Addresses",
			pageTitle: "Engineering NAT IP Addresses",
		},
	},
	{
		path: "endpoint-ranges",
		name: "engineering-endpoint-ranges",
		component: () => import("./EngineeringEndpointRanges.vue"),
		meta: {
			requiresPriv: "canAccessIPMan",
			includeInNav: true,
			navTitle: "Endpoint Ranges",
			pageTitle: "Engineering Endpoint Ranges",
		},
	},
	{
		path: "tcp-capture-history",
		name: "engineering-tcp-capture-history",
		component: () => import("../Diagnostics/DiagnosticsTcpCaptureHistory.vue"),
		meta: {
			includeInNav: true,
			navTitle: "TCP Capture History",
			pageTitle: "TCP Capture History",
		},
	},
	{
		path: "customer-setup",
		name: "engineering-customer-setup",
		component: () => import("./EngineeringCustomerSetup.vue"),
		meta: {
			includeInNav: true,
			requiresPriv: "canAccessEngineeringCustomerSetup",
			navTitle: "Customer Setup",
			pageTitle: "Engineering Customer Setup",
		},
	},
	{
		path: "endpoints",
		name: "engineering-endpoints",
		component: () => import("./EngineeringEndpoints.vue"),
		meta: {
			requiresPriv: "canAccessEngineeringProcessors",
			includeInNav: true,
			navTitle: "Endpoints",
			pageTitle: "Engineering Endpoints",
		},
	},
	{
		path: "vpns",
		name: "engineering-vpns",
		component: () => import("./EngineeringVpns.vue"),
		meta: {
			requiresPriv: "canViewVPNServers",
			includeInNav: true,
			navTitle: "VPN",
			pageTitle: "Engineering VPN",
		},
	},
	{
		path: "vlans",
		name: "engineering-vlans",
		component: () => import("./EngineeringVlans.vue"),
		meta: {
			requiresPriv: "canModifyVLANs",
			includeInNav: true,
			navTitle: "VLAN",
			pageTitle: "Engineering VLAN",
		},
	},
	{
		path: "alerts",
		name: "engineering-alerts",
		component: () => import("./EngineeringAlerts.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Alerts",
			pageTitle: "Engineering Alerts",
		},
	},
	{
		path: "router-events",
		name: "engineering-router-events",
		component: () => import("./EngineeringRouterEvents.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Router Events",
			pageTitle: "Engineering Router Events",
		},
	},
	{
		path: "crosstalk",
		name: "engineering-crosstalk",
		redirect: { name: "engineering-subnet-pairs" },
		component: () => import("./EngineeringCrosstalk.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Crosstalk",
			pageTitle: "Engineering Crosstalk",
		},
		children: [
			{
				path: "subnet-pairs",
				name: "engineering-subnet-pairs",
				component: () => import("./EngineeringSubnetPairs.vue"),
				meta: {
					pageTitle: "Subnet Pairs",
				},
			},
			{
				path: "pending-change-requests",
				name: "engineering-pending-change-requests",
				component: () => import("./EngineeringPendingChangeRequests.vue"),
				meta: {
					pageTitle: "Pending Change Requests",
				},
			},
			{
				path: "completed-change-requests",
				name: "engineering-completed-change-requests",
				component: () => import("./EngineeringCompletedChangeRequests.vue"),
				meta: {
					pageTitle: "Completed Change Requests",
				},
			},
		],
	},
	{
		path: "rulesets",
		name: "engineering-rulesets",
		component: () => import("./EngineeringRulesets.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Rulesets",
			pageTitle: "Engineering Rulesets",
		},
	},
	{
		path: "categories",
		name: "engineering-categories",
		component: () => import("./EngineeringCategories.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Categories",
			pageTitle: "Engineering Categories",
		},
	},
	{
		path: "vrf",
		name: "engineering-vrf",
		component: () => import("./EngineeringVrf.vue"),
		meta: {
			includeInNav: true,
			navTitle: "VRF",
			pageTitle: "Engineering VRF",
		},
	},
	{
		path: "endpoint-solution-management",
		name: "engineering-endpoint-solution-management",
		component: () => import("./EngineeringEndpointSolutionManagement.vue"),
		meta: {
			includeInNav: true,
			navTitle: "Endpoint/Solution Management",
			pageTitle: "Engineering Endpoint/Solution Management",
		},
	},
];

export default {
	path: "/engineering",
	component: () => import("@/components/Nav/NavRoute.vue"),
	meta: {
		requiresAuth: true,
		requiresPriv: "canAccessEngineering",
		navTitle: "Engineering",
		pageTitle: "Engineering",
	},
	children: ROUTES,
	props: {
		navItems: ROUTES,
	},
};
